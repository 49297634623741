<!-- APP版本管理页面 -->
<template>
  <div class="appVersionManagement">
    <div class="top-area clr">
      <Button type="primary">新增</Button>
      <Input style="width:240px" class="fr" search placeholder="请输入..." />
    </div>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" size="small" :height="tableHeight">
        <template slot-scope="{ row, index }" slot="action">
          <Button type="primary" size="small" style="margin-right: 5px" @click="edit(index)">修改</Button>
          <Button type="error" size="small" @click="remove(index)">删除</Button>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableHeight: window.innerHeight - 250,
      total: 0,
      current: 1,
      columns: [
        {
          title: 'id',
          key: 'id'
        },
        {
          title: 'app名称',
          key: 'name'
        },
        {
          title: '版本号',
          key: 'versionNumber'
        },
        {
          title: '上线日期',
          key: 'date'
        },
        {
          title: '操作',
          slot: 'action',
        },
      ],
      dataList: [
        {
          id: 1,
          name: 'app',
          versionNumber: 'v1.0.0',
          date: '2024-01-01'
        },
        {
          id: 2,
          name: 'app',
          versionNumber: 'v1.0.0',
          date: '2024-01-01'
        },
        {
          id: 3,
          name: 'app',
          versionNumber: 'v1.0.0',
          date: '2024-01-01'
        },
        {
          id: 4,
          name: 'app',
          versionNumber: 'v1.0.0',
          date: '2024-01-01'
        }
      ]
    };
  },
  methods: {
    findPageDataList() { },
    remove() { },
    edit() { },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
  }
}

</script>
<style lang='scss' scoped>
.appVersionManagement {
}
</style>